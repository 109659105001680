//题库导入
const objectSpanMethod = function (rowIndex, columnIndex, tableData) {
  if (columnIndex === 0) {
    const _row = flitterData(tableData).arr1[rowIndex]
    return handleReturn(_row)
  }
  if (columnIndex === 1) {
    const _row = flitterData(tableData).arr2[rowIndex]
    return handleReturn(_row)
  }
  if (columnIndex === 2) {
    const _row = flitterData(tableData).arr3[rowIndex]
    return handleReturn(_row)
  }
  if (columnIndex === 3) {
    const _row = flitterData(tableData).arr4[rowIndex]
    return handleReturn(_row)
  }
  if (columnIndex === 4) {
    const _row = flitterData(tableData).arr5[rowIndex]
    return handleReturn(_row)
  }
  if (columnIndex === 5) {
    const _row = flitterData(tableData).arr6[rowIndex]
    return handleReturn(_row)
  }
  if (columnIndex === 6) {
    const _row = flitterData(tableData).arr7[rowIndex]
    return handleReturn(_row)
  }
  if (columnIndex === 7) {
    const _row = flitterData(tableData).arr8[rowIndex]
    return handleReturn(_row)
  }
  if (columnIndex === 8) {
    const _row = flitterData(tableData).arr9[rowIndex]
    return handleReturn(_row)
  }
  if (columnIndex === 9) {
    const _row = flitterData(tableData).arr10[rowIndex]
    return handleReturn(_row)
  }
  if (columnIndex === 10) {
    const _row = flitterData(tableData).arr11[rowIndex]
    return handleReturn(_row)
  }
  if (columnIndex === 11) {
    const _row = flitterData(tableData).arr12[rowIndex]
    return handleReturn(_row)
  }
  if (columnIndex === 15) {
    const _row = flitterData(tableData).arr16[rowIndex]
    return handleReturn(_row)
  }
  if (columnIndex === 18) {
    const _row = flitterData(tableData).arr19[rowIndex]
    return handleReturn(_row)
  }
}

const flitterData = (arr) => {
  let spanArr1 = []
  let spanArr2 = []
  let spanArr3 = []
  let spanArr4 = []
  let spanArr5 = []
  let spanArr6 = []
  let spanArr7 = []
  let spanArr8 = []
  let spanArr9 = []
  let spanArr10 = []
  let spanArr11 = []
  let spanArr12 = []
  let spanArr16 = []
  let spanArr19 = []

  let concat1 = 0
  let concat2 = 0
  let concat3 = 0
  let concat4 = 0
  let concat5 = 0
  let concat6 = 0
  let concat7 = 0
  let concat8 = 0
  let concat9 = 0
  let concat10 = 0
  let concat11 = 0
  let concat12 = 0
  let concat16 = 0
  let concat19 = 0
  arr.forEach((item, index) => {
    if (index === 0) {
      spanArr1.push(1)
      spanArr2.push(1)
      spanArr3.push(1)
      spanArr4.push(1)
      spanArr5.push(1)
      spanArr6.push(1)
      spanArr7.push(1)
      spanArr8.push(1)
      spanArr9.push(1)
      spanArr10.push(1)
      spanArr11.push(1)
      spanArr12.push(1)
      spanArr16.push(1)
      spanArr19.push(1)
    } else {
      if (item.CompName === arr[index - 1].CompName && item.Interpretation === arr[index - 1].Interpretation) {
        //第二列需合并相同内容的判断条件
        spanArr1[concat1] += 1
        spanArr1.push(0)
      } else {
        spanArr1.push(1)
        concat1 = index
      }
      if (item.DeptName === arr[index - 1].DeptName && item.Interpretation === arr[index - 1].Interpretation) {
        spanArr2[concat2] += 1
        spanArr2.push(0)
      } else {
        spanArr2.push(1)
        concat2 = index
      }
      if (item.PostName === arr[index - 1].PostName && item.Interpretation === arr[index - 1].Interpretation) {
        spanArr3[concat3] += 1
        spanArr3.push(0)
      } else {
        spanArr3.push(1)
        concat3 = index
      }
      if (item.FunctionName === arr[index - 1].FunctionName && item.Interpretation === arr[index - 1].Interpretation) {
        spanArr4[concat4] += 1
        spanArr4.push(0)
      } else {
        spanArr4.push(1)
        concat4 = index
      }
      if (item.FileNo === arr[index - 1].FileNo && item.Interpretation === arr[index - 1].Interpretation) {
        spanArr5[concat5] += 1
        spanArr5.push(0)
      } else {
        spanArr5.push(1)
        concat5 = index
      }
      if (item.FileName === arr[index - 1].FileName && item.Interpretation === arr[index - 1].Interpretation) {
        spanArr6[concat6] += 1
        spanArr6.push(0)
      } else {
        spanArr6.push(1)
        concat6 = index
      }
      if (item.Question === arr[index - 1].Question) {
        spanArr7[concat7] += 1
        spanArr7.push(0)
      } else {
        spanArr7.push(1)
        concat7 = index
      }
      if (item.AuditMethod === arr[index - 1].AuditMethod) {
        spanArr8[concat8] += 1
        spanArr8.push(0)
      } else {
        spanArr8.push(1)
        concat8 = index
      }
      if (item.Answer === arr[index - 1].Answer) {
        spanArr9[concat9] += 1
        spanArr9.push(0)
      } else {
        spanArr9.push(1)
        concat9 = index
      }
      if (item.ProcessName === arr[index - 1].ProcessName) {
        spanArr10[concat10] += 1
        spanArr10.push(0)
      } else {
        spanArr10.push(1)
        concat10 = index
      }
      if (item.ExamQuestion === arr[index - 1].ExamQuestion) {
        spanArr11[concat11] += 1
        spanArr11.push(0)
      } else {
        spanArr11.push(1)
        concat11 = index
      }
      if (item.DisturbQuestion === arr[index - 1].DisturbQuestion) {
        spanArr12[concat12] += 1
        spanArr12.push(0)
      } else {
        spanArr12.push(1)
        concat12 = index
      }
      if (item.Interpretation === arr[index - 1].Interpretation) {
        spanArr16[concat16] += 1
        spanArr16.push(0)
      } else {
        spanArr16.push(1)
        concat16 = index
      }
      if (item.Version === arr[index - 1].Version && item.Interpretation === arr[index - 1].Interpretation) {
        spanArr19[concat19] += 1
        spanArr19.push(0)
      } else {
        spanArr19.push(1)
        concat19 = index
      }
    }
  })
  return {
    arr1: spanArr1,
    arr2: spanArr2,
    arr3: spanArr3,
    arr4: spanArr4,
    arr5: spanArr5,
    arr6: spanArr6,
    arr7: spanArr7,
    arr8: spanArr8,
    arr9: spanArr9,
    arr10: spanArr10,
    arr11: spanArr11,
    arr12: spanArr12,
    arr16: spanArr16,
    arr19: spanArr19,
  }
}

const handleReturn = (row) => {
  const col = row > 0 ? 1 : 0
  return {
    rowspan: row,
    colspan: col,
  }
}

export { objectSpanMethod }
