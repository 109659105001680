<template>
  <div>
    <div class="_BoxHeader">
      <span @click="beforeunloadFn()">7S题库导入</span>
    </div>
    <div class="PageContain" v-loading="loading">
      <div class="_SelectHeader">
        <span class="_Title">列表信息</span>
        <div v-show="!isNotPurview">
          <el-button type="primary" @click="exportExcel">导出表头</el-button>
          <el-button type="primary" @click="uploadFile">导入</el-button>
          <el-button type="primary" @click="surePost">确认上传</el-button>
        </div>
      </div>
      <input
        type="file"
        @change="importFile(this)"
        id="imFile"
        style="display: none"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <div class="contain-box" :style="{ height: elementHeight + 'px' }">
        <div>
          <!-- <div class="letter">
            <span
              v-for="(item, index) in letter"
              :key="index"
              :class="[index < 22 ? 'width1' : 'width2']"
              class="item"
              >{{ item }}</span
            >
          </div>-->
          <el-table
            :data="tableList"
            :height="600"
            style="width: 100%"
            :header-cell-style="{
              background: '#E8EBF4',
              color: '#5C5C5C',
              borderColor: '#C0C0C0',
            }"
            border
            id="table"
          >
            <!-- <el-table-column type="selection" width="80"></el-table-column> -->
            <el-table-column prop="compName" label="公司" width="180">
              <template slot-scope="scope">
                <textarea type name v-model="scope.row.compName" />
              </template>
            </el-table-column>
            <el-table-column prop="deptName" label="部门" width="180">
              <template slot-scope="scope">
                <textarea type name v-model="scope.row.deptName" />
              </template>
            </el-table-column>
            <el-table-column prop="postName" label="岗位 " width="80">
              <template slot-scope="scope">
                <textarea type name v-model="scope.row.postName" />
              </template>
            </el-table-column>
            <el-table-column prop="functionName" label="职能" width="80">
              <template slot-scope="scope">
                <textarea type name v-model="scope.row.functionName" />
              </template>
            </el-table-column>
            <el-table-column prop="fileNo" label="文件编号" width="80">
              <template slot-scope="scope">
                <textarea type name v-model="scope.row.fileNo" />
              </template>
            </el-table-column>
            <el-table-column prop="fileName" label="文件名称" width="80">
              <template slot-scope="scope">
                <textarea type name v-model="scope.row.fileName" />
              </template>
            </el-table-column>
            <el-table-column prop="question" label="检查内容" width="80">
              <template slot-scope="scope">
                <textarea type name v-model="scope.row.question" />
              </template>
            </el-table-column>
            <el-table-column prop="answer" width="380" label="标准答案">
              <template slot-scope="scope">
                <textarea type name v-model="scope.row.answer" />
              </template>
            </el-table-column>
            <el-table-column prop="riskPoints" label="风险点" width="80">
              <template slot-scope="scope">
                <textarea type name v-model="scope.row.riskPoints" />
              </template>
            </el-table-column>
            <el-table-column prop="riskMeasures" label="风险应对措施" width="80">
              <template slot-scope="scope">
                <textarea type name v-model="scope.row.riskMeasures" />
              </template>
            </el-table-column>
            <el-table-column prop="version" label="版本号" width="40"></el-table-column>
            <el-table-column prop align="center" label="检查类别" width="680">
              <el-table-column prop label="1">
                <el-table-column prop="areaAudit" label="现场检查" width="80">
                  <template slot-scope="scope">
                    <div>{{ scope.row.AreaAudit ? '√' : '' }}</div>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop label="2">
                <el-table-column prop="fileAudit" label="文件检查" width="80">
                  <template slot-scope="scope">
                    <div>{{ scope.row.FileAudit ? '√' : '' }}</div>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table-column>
            <el-table-column align="center" label="所在区域">
              <el-table-column
                prop="arrArea"
                :label="index + 1 + ''"
                v-for="(item, index) in areaList"
                :key="index"
              >
                <el-table-column :label="item.areaName">
                  <template slot-scope="scope">
                    <div>
                      {{
                      scope.row.arrArea.split(",").includes(item.areaName)
                      ? "√"
                      : ""
                      }}
                    </div>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <Dialog
      title="查看错误"
      :showDialog="showErrorDialog"
      width="80%"
      showTowText="true"
      @handleClose="handleClose"
      @handleReqData="handleClose"
      top="1vh"
      class="error-dialog"
    >
      <el-table :data="tableList2" :height="elementHeight" style="width: 100%" border id="table">
        <el-table-column prop="fileNo" label="文件名" width="320"></el-table-column>
        <el-table-column prop="saveTime" label="导入时间" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.saveTime.split('T')[0] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="errorLog" label="错误信息" show-overflow-tooltip>
          <template slot-scope="scope">
            <span @click="clickText(scope.row.errorLog)">
              {{
              scope.row.errorLog
              }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </Dialog>
    <Dialog
      title="错误信息"
      :showDialog="showErrorText"
      width="55%"
      showTowText="true"
      @handleClose="showErrorText = false"
      @handleReqData="showErrorText = false"
      top="5vh"
      class="error-dialog"
    >
      <div class="error-text">
        <span>{{ errorText }}</span>
      </div>
    </Dialog>
  </div>
</template>

<script>
import XLSX from "xlsx";
import { get, post } from "../../api/http";
import Dialog from "../../components/dialog/Dialog.vue";
import { objectSpanMethod } from "../../modules/TableConflate";
import { exportExcel } from "../../modules/ExportExcel";

export default {
  components: { Dialog },
  data() {
    return {
      elementHeight: 0,
      loading: false,
      tableList: [],
      showErrorDialog: false,
      tableList2: [],
      errorText: "",
      excelName: "",
      showErrorText: false,
      isNotPurview: false,
      list: [
        "厂区环境-生产现场",
        "厂区环境-营业厅",
        "维修区域",
        "仓库区域",
        "实验室区域",
        "办公区域",
        "饭堂区域",
        "宿舍区域",
        "工程区域",
        "7S管理",
      ],
      letter: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
      ],
      reqList: [],
      propertyList: [],
      dataList: [],
      run: true,
      wb: "",
      areaList: [],
    };
  },

  mounted() {
    this.getList();
    this.imFile = document.getElementById("imFile");
    this.getElementHeight();
    var roleName = JSON.parse(
      sessionStorage.getItem("userInfo")
    ).roleName.split(",");
    if (
      roleName.indexOf("股份公司管理员") == -1 &&
      roleName.indexOf("公司管理员") == -1 &&
      roleName.indexOf("部门管理员") == -1
    ) {
      this.isNotPurview = true;
    }
  },
  methods: {
    getList() {
      get("/api/Area/GetList").then((res) => {
        this.option2 = res.data;
        this.areaList = res.data.filter((item, index, self) => {
          return (
            index === self.findIndex((obj) => obj.areaName === item.areaName)
          );
        });
      });
    },
    exportExcel() {
      this.tableList = [];
      this.$nextTick(() =>{
        if(this.tableList.length == 0){
          return exportExcel("#table", "日常检查题库.xlsx");
        }
      })
    },
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById("table").offsetTop + 60 + 100);
      });
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      return objectSpanMethod(rowIndex, columnIndex, this.tableList);
    },
    // 导入excel
    uploadFile: function () {
      // 点击导入按钮
      this.imFile.click();
    },
    importFile: function () {
      let obj = this.imFile;
      if (!obj.files) {
        return;
      }
      // this.loading = true
      this.tableList = [];
      var f = obj.files[0];
      var reader = new FileReader();
      let $this = this;
      reader.onload = function (e) {
        var data = e.target.result;
        if ($this.rABS) {
          $this.wb = XLSX.read(btoa(this.fixdata(data)), {
            // 手动转化
            type: "base64",
          });
        } else {
          $this.wb = XLSX.read(data, {
            type: "binary",
          });
        }
        var excelData = XLSX.utils.sheet_to_json(
          $this.wb.Sheets[$this.wb.SheetNames[0]]
        );
        var stopExecution = false;
        excelData = excelData.slice(2);

        excelData.map((item, index) => {
          if (stopExecution) return;
          // 第一和第二行为标题的情况下不导入
          if (
            (index == 0 || index == 1) &&
            !Object.prototype.hasOwnProperty.call(item, "公司")
          )
            return;
          if (
            //从exal表第一行开始必须要有公司
            !Object.prototype.hasOwnProperty.call(item, "公司") &&
            index == 2
          ) {
            return (stopExecution = true);
          }
          // 处理合并的数据(现状：学习多行与题库一行合并)
          // if (Object.prototype.hasOwnProperty.call(item, '标准答案')) {
          //   $this.$message({
          //     type: 'warning',
          //     message: '第' + (index - 1) + '行标准要求为标准答案，字段错误',
          //   })
          //   return (stopExecution = true)
          // }
          if (!Object.prototype.hasOwnProperty.call(item, "公司")) {
            item.公司 = excelData[index - 1].公司;
          }
          if (!Object.prototype.hasOwnProperty.call(item, "部门")) {
            item.部门 = excelData[index - 1].部门;
          }
          if (!Object.prototype.hasOwnProperty.call(item, "岗位")) {
            item.岗位 = excelData[index - 1].岗位;
          }
          if (!Object.prototype.hasOwnProperty.call(item, "职能")) {
            item.职能 = excelData[index - 1].职能;
          }
          if (!Object.prototype.hasOwnProperty.call(item, "文件编号")) {
            item.文件编号 = excelData[index - 1].文件编号;
          }
          if (!Object.prototype.hasOwnProperty.call(item, "文件名称")) {
            item.文件名称 = excelData[index - 1].文件名称;
          }
          if (!Object.prototype.hasOwnProperty.call(item, "版本号")) {
            item.版本号 = excelData[index - 1].版本号;
          }
          const emptyFields = [];
          $this.areaList.forEach((item, index) => {
            emptyFields.push(`__EMPTY_${index + 1}`);
          });
          emptyFields.pop();
          item.所在区域 = emptyFields.reduce((acc, field) => {
            if (item[field]) {
              acc += "," + item[field];
            }
            return acc;
          }, item.所在区域);
          var data = {
            compName: item.公司,
            deptName: item.部门,
            postName: item.岗位,
            functionName: item.职能,
            fileNo: item.文件编号,
            fileName: item.文件名称,
            question: item.检查内容,
            // auditMethod: item.审核方式, //审核方式
            answer: item.标准答案,
            riskPoints: item.风险点,
            riskMeasures: item.风险应对措施,
            version: item.版本号,
            // module: item.检查模块,
            arrAuditType: "null",
            // arrArea: "null",
            // IsCurrency: item.通用 ? "√" : "",
            // IsFuncCurrency: item.职能通用 ? "√" : "",
            // IsSpecial: item.专指 ? "√" : "",
            AreaAudit: item.检查类别 ? "√" : "", //现场检查
            FileAudit: item.__EMPTY ? "√" : "", //文件检查
            arrArea: item.所在区域
              ? item.所在区域.replace(/^undefined,/, "")
              : "",
            // __EMPTY_0: item.所在区域 ? "√" : "",
            // __EMPTY_1: item.__EMPTY_1,
            // __EMPTY_2: item.__EMPTY_2,
            // __EMPTY_3: item.__EMPTY_3,
            // __EMPTY_4: item.__EMPTY_4,
            // __EMPTY_5: item.__EMPTY_5,
            // __EMPTY_6: item.__EMPTY_6,
            // __EMPTY_7: item.__EMPTY_7,
            // __EMPTY_8: item.__EMPTY_8,
            // __EMPTY_56: item.__EMPTY_56,
          };
          $this.tableList.push(data);
        });
        if ($this.tableList.length != 0) {
          $this.importList(obj.files[0].name.split(".")[0]);
        } else {
          console.log("格式不符合的数据=", excelData);
          $this.$message({
            type: "warning",
            message: "导入文件不符合",
          });
          $this.loading = false;
        }
      };
      if (this.rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },

    importList(excelName) {
      this.dataList = [];
      this.tableList.map((item) => {
        // let ArrList = this.handleList(item);
        this.dataList.push({
          CompName: item.compName,
          DeptName: item.deptName,
          PostName: item.postName,
          FunctionName: item.functionName,
          FileNo: item.fileNo,
          FileName: item.fileName ? item.fileName : "",
          Question: item.question ? item.question : "",
          Answer: (item.answer ? item.answer : "") + "",
          Version: item.version ? item.version : "",
          // Module: item.module ? item.module : "",
          RiskPoints: item.riskPoints ? item.riskPoints : "",
          RiskMeasures: item.riskMeasures ? item.riskMeasures : "",
          // IsCurrency: 0,
          // IsFuncCurrency: 0,
          // IsSpecial: 0,
          AreaAudit: item.AreaAudit ? 1 : 0,
          FileAudit: item.FileAudit ? 1 : 0,
          arrArea: item.arrArea,
          arrAuditType: "7S管理",
        });
      });
      this.excelName = excelName;
      // this.postBatchAdd(this.dataList, excelName)
    },
    surePost() {
      this.loading = true;
      this.postBatchAdd(this.dataList, this.excelName);
    },
    handleList(item) {
      var list = [];
      if (item.__EMPTY_0) list.push(this.list[0]);
      if (item.__EMPTY_1) list.push(this.list[1]);
      if (item.__EMPTY_2) list.push(this.list[2]);
      if (item.__EMPTY_3) list.push(this.list[3]);
      if (item.__EMPTY_4) list.push(this.list[4]);
      if (item.__EMPTY_5) list.push(this.list[5]);
      if (item.__EMPTY_6) list.push(this.list[6]);
      if (item.__EMPTY_7) list.push(this.list[7]);
      if (item.__EMPTY_8) list.push(this.list[8]);
      //if (item.__EMPTY_56 != '') list.push(this.list[9])
      return list;
    },
    async postBatchAdd(list, excelName) {
      await post("/api/QuestionPool/BatchAdd?ExcelName=" + excelName, {
        data: JSON.stringify(list),
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: "验证成功,开始导入",
            });
            this.run = true;
            this.dataList.map((i, index) => {
              if (!this.run) return;
              this.importData(i, index);
            });
          } else {
            this.showError(resp.message);
          }
        })
        .catch(() => {
          this.loading = false;
          this.$message.error("系统异常,请稍后再试");
        });
    },
    async importData(i, index) {
      await post("/api/QuesPoolAndExam/BatchAdd", i)
        .then((resp) => {
          if (resp.code != 200) {
            this.run = false;
            console.log(
              "1)第" + (index + 1) + "行导入失败",
              "2)错误行数：",
              i,
              "3)错误原因：" + resp.message
            );
            this.$message({
              type: "error",
              message:
                ("1)第" + index + 1 + "行导入失败",
                "2)错误行数：" + i,
                "3)错误原因：" + resp.message),
            });
            this.showError(resp.message);
            return;
          }
          if (index + 1 == this.dataList.length) {
            this.loading = false;
            this.$message({
              type: "success",
              message: "已全部导入",
            });
          }
        })
        .catch(() => {
          this.run = false;
          this.loading = false;
          this.$message.error("系统异常,请稍后再试");
        });
    },
    showError(msg) {
      this.$confirm(msg, {
        // confirmButtonText: "查看错误",
        cancelButtonText: "关闭",
        type: "error",
      })
        .then(() => {
          // get(
          //   "/api/QuesErrorlog/GetByUserId?UserId=" +
          //     JSON.parse(sessionStorage.getItem("userInfo")).id
          // ).then((resp) => {
          //   if (resp.code == 200) {
          //     this.tableList2 = resp.data;
          //   }
          // });
          // this.showErrorDialog = true;
          location.reload()
        })
        .catch(() => {
          location.reload();
        });
    },
    handleClose() {
      this.showErrorDialog = false;
      location.reload();
    },
    clickText(e) {
      this.errorText = e;
      this.showErrorText = true;
    },
  },
};
</script>

<style lang="less" scoped>
._SelectHeader {
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    padding-right: 20px;
  }
}
/deep/ .el-table .cell {
  line-height: 15px !important;
}
.contain-box {
  position: absolute;
  top: 50px;
  bottom: 0;
  overflow-y: hidden;
  overflow-x: auto;
  background-color: #e8e9ee;
  width: 100%;
  > div {
    overflow: auto;
    border: 1px solid #dbdbdb;
    display: inline-block;
    // padding-right: 20px;
  }
}
textarea {
  outline: none;
  border: 0px;
  width: 100%;
  height: 120px;
  resize: none;
}
.letter {
  display: flex;
  flex-wrap: nowrap;
}
.letter .item {
  text-align: center;
  display: inline-block;
  border-right: 1px solid #dbdbdb;
  background-color: white;
  padding: 2px 0;
  border-bottom: 1px solid #dbdbdb;
}
.width1 {
  width: 119.2px;
}
.width2 {
  width: 39.2px;
}
/deep/ .el-dialog__wrapper {
  overflow: hidden !important;
}
.error-text {
  width: 100%;
  height: 400px;
  overflow: scroll;
}
</style>
